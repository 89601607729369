<template>
  <div class="modalContainer cookieSampleModal footerModal" v-if="cookieModal">
    <div class="modalPopup">
      <div class="popupHeader text-center">
        <span class="popupSubtitle">Einstellungen</span>
        <h2>Cookies und Tracking</h2>
        <p>Wir verwenden Cookies, um Ihnen ein optimales personalisiertes Webseiten-Erlebnis zu bieten. Entscheiden
          Sie selbst, welche Kategorien Sie zulassen möchten.
          <router-link :to="{ name: 'DataProtection' }" target="_blank">
            Weitere Informationen
          </router-link>
        </p>
        <!-- <div class="spacer"></div> -->
        <span class="iconRight">
            <button class="iconButton small" type="button" @click="cookieModal = false">
              <img :src="icons.close"/>
            </button>
          </span>
      </div>
      <div class="popupContent">
        <div class="cookiesList">
          <ul>
            <li v-for="(item, i) in cookiesList" :key="i">

              <cookie-checklist :item="item"
                                :large="false"
                                :trigger="true"
                                v-on:triggerCollapse="triggerCollapse"
                                v-on:category-accepted="cookieCategoryAccepted"
                                v-on:category-declined="cookieCategoryDeclined"></cookie-checklist>

              <p
                v-if="item.slug == 'customCategory-2547490b-c2b7-47b6-a503-33c5fd5eb077' && !item.checked && functionalCookieAccepted"
                class="cookie-danger">
                ACHTUNG: Wollen Sie die Funktions-Cookies wirklich deaktivieren? <br/>
                Bei Bestätigung Ihrer Auswahl löschen wir alle Daten und senden Sie zurück zur Startseite.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="popupActions flex justify-center">
        <div class="text-center">
          <button class="button rounded block" @click.prevent="acceptCookieModal()">
            <span>Einstellungen speichern</span>
          </button>
          <button class="button rounded block" @click="acceptAllServices">
            <span>Alle akzeptieren</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uiStore from '@/store/modules/ui'
import CookieChecklist from './cookie-checklist'
import dynamicModule from '@/store/utils/dynamicModule'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CookiePopup',
  components: { CookieChecklist },
  data () {
    return {
      userCentrics: null,
      cookiesList: [],
      icons: {
        link: require('@/assets/images/icon-link.svg'),
        phone: require('@/assets/images/icon-telefon.svg'),
        mail: require('@/assets/images/icon-mail.svg'),
        video: require('@/assets/images/icon-video.svg'),
        message: require('@/assets/images/icon-message.svg'),
        details: require('@/assets/images/icon-details-text.svg'),
        camera: require('@/assets/images/icon-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg')
      }
    }
  },
  computed: {
    ...mapState('ui', ['cookieModal']),
    functionalCookieAccepted () {
      return localStorage.getItem('uc_functional') === 'true'
    },
    dateProtectionUrl () {
      return process.env.VUE_APP_DATA_PROTECTION_URL
    }
  },
  mixins: [
    dynamicModule('ui', uiStore)
  ],
  methods: {
    ...mapActions('ui', ['changeCookieModal']),
    updateUserCentricsServices (serviceIds, checked) {
      let self = this
      let userDecisions = []
      this.$userCentrics.getCategoriesFullInfo().then(categories => {
        categories.forEach(function (category) {
          category.services.forEach(function (service) {
            if (serviceIds.includes(service.id)) {
              userDecisions.push({
                serviceId: service.id,
                status: checked
              })
            } else {
              userDecisions.push({
                serviceId: service.id,
                status: service.consent.status
              })
            }
          })
        })

        self.$userCentrics.updateServices(userDecisions).then(() => {
          self.$userCentrics.getCategories()
        })
      })
    },
    cookieCategoryAccepted (services, functional = false) {
      this.updateUserCentricsServices(services, true)
    },
    cookieCategoryDeclined (services) {
      this.updateUserCentricsServices(services, false)
    },
    acceptAllServices () {
      let self = this
      this.$userCentrics.acceptAllServices().then(function () {
        self.$userCentrics.getCategories()

        localStorage.setItem('uc_essential', true)
        localStorage.setItem('uc_functional', true)
        localStorage.setItem('uc_performance', true)

        // !self.hasRecaptcha() && self.createRecaptcha()
        // !self.$gtm.enabled() && self.$gtm.enable(true)

        self.$store.dispatch('user/addCookieConsent', true)
        self.$store.dispatch('user/addCookies', true)
        // self.addCookies(true)
        self.changeCookieModal(false)
      })
    },
    triggerCollapse (slug) {
      for (let i = 0; i < this.cookiesList.length; i++) {
        if (this.cookiesList[i].slug !== slug) {
          this.cookiesList[i].visible = false
        }
      }
    },
    acceptCookieModal () {
      localStorage.setItem('uc_essential', true)
      // !this.hasRecaptcha() && this.createRecaptcha()
      this.$store.dispatch('user/addCookies', true)
      this.changeCookieModal(false)

      let _self = this

      // this.$store.dispatch('user/addCookieConsent', true)
      if (localStorage.getItem('uc_functional') === 'false') {
        // debugger
        sessionStorage.clear()
        localStorage.clear()
        setTimeout(function () {
          sessionStorage.clear()
          _self.$store.replaceState({})
          localStorage.clear()
          // window.location.href = '/start/'
          _self.$router.go()
        }, 1000)
      }
      // if (localStorage.getItem('uc_performance')) {
      //   // !this.hasGtm() && this.createGtm()
      //   // !this.$gtm.enabled() && this.$gtm.enable(true)
      // } else {
      //   this.removeGtm()
      //   this.$gtm.enable(false)
      // }
    }
  },
  mounted () {
    let self = this
    this.$userCentrics.getCategoriesFullInfo().then(function (categories) {
      categories.forEach(function (item) {
        let checked = true
        item.services.forEach(function (service) {
          checked = checked && service.consent.status
        })

        item.checked = checked
        item.visible = false
      })

      self.cookiesList = categories
    })
  }
}
</script>

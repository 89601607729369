<template>
  <div class="singleChecklist cookieChecklist"
       :class="[item.visible ? 'opened' : 'collapsed', large ? 'large' : 'default', item.checked ? 'done' : 'notDone', type === 'blue' ? 'blueBoxCheck' : '']">
    <div class="checklistDetails">
      <div class="checkTitle medium" :class="{ simple: !item.checked }">
        <span class="checkboxToggle">
          <div class="rowInput">
            <label class="toggleCheckbox cursor-pointer medium" :class="[item.isEssential ? 'essential' : '']">
              <input type="checkbox" :checked="true" :disabled="true" class="hiddenInput" v-if="item.required"/>
              <input type="checkbox" :disabled="item.isEssential" v-model="item.checked" class="hiddenInput" v-else/>
              <span></span>
            </label>
          </div>
        </span>
        <h4 @click="handleToggle(item)" v-text="item.label"></h4>
        <span class="toggleButton" @click="handleToggle(item)">
          <img v-if="!item.visible" :src="icons.plusIcon" width="100%"/>
          <img v-else :src="icons.minusIcon" width="100%"/>
        </span>
      </div>
      <transition name="slide">
        <div class="checkDescription text-left" v-if="large || item.visible">
          <nl2br tag="p" :text="item.description"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Nl2br from './Nl2br'
import { mapActions, mapState } from 'vuex'
import userStore from '@/store/modules/user'
import dynamicModule from '@/store/utils/dynamicModule'

export default {
  name: 'CookieChecklist',
  components: { Nl2br },
  props: {
    item: Object,
    large: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: null
    },
    trigger: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    dynamicModule('user', userStore)
  ],
  computed: {
    ...mapState('user', ['user'])
  },
  data () {
    return {
      descriptionVisible: false,
      icons: {
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg')
      }
    }
  },
  methods: {
    ...mapActions('user', ['addUser', 'addCheckedList', 'addCookies', 'addCookieConsent']),
    handleToggle (item) {
      if (typeof (item.visible) === 'undefined') this.$set(item, 'visible', true)
      else item.visible = !item.visible

      item.visible && this.trigger && this.$emit('triggerCollapse', this.item.slug)
    }
  },
  watch: {
    'item.checked': function (value) {
      let functional = 'customCategory-2547490b-c2b7-47b6-a503-33c5fd5eb077'
      let performance = 'customCategory-c2b45c72-730a-497b-9f4e-7405940f28df'

      if (value) {
        if (this.item.slug === functional) {
          localStorage.setItem('uc_functional', true)
          this.addCookies(true)
          this.addCookieConsent(true)
        }
        if (this.item.slug === performance) {
          localStorage.setItem('uc_performance', true)
          // !this.hasGtm() && this.createGtm()
          // this.$gtm.enable(true)
        }

        this.$emit('category-accepted', this.item.services.map(service => service.id))
      } else {
        if (this.item.slug === functional) {
          localStorage.setItem('uc_functional', false)
          // this.addCookies(false)
          // this.addCookieConsent(false)
        }
        if (this.item.slug === performance) {
          localStorage.setItem('uc_performance', false)
          // this.removeGtm()
          // this.$gtm.enable(false)
        }
        this.$emit('category-declined', this.item.services.map(service => service.id))
      }
    }
  }
}
</script>

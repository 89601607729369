<template>
  <div>
    <main-header :home="isHome" :isFixed="isScrolling" v-if="!isStart && $route.meta.hideHeader !== true"></main-header>
    <router-view v-if="isMounted"/>
    <main-footer :start="isStart" :mobile="isMobile" v-if="$route.meta.hideHeader !== true"></main-footer>
    <modal v-if="showModal"></modal>
    <cookie-popup v-if="cookieModal"></cookie-popup>
    <popup-image v-if="surveyPopupImage" @handleToggle="handleToggle" :item="surveyPopupImage"></popup-image>
    <popup-text v-if="surveyPopupText" @handleToggle="handleToggle" :item="surveyPopupText"></popup-text>
    <div class="modalContainer" v-if="popups.cookie && cookiePopup && !user.cookieConsent">
      <div class="modalPopup">
        <div class="popupHeader">
          <h2 v-if="popups.cookie.title" v-html="popups.cookie.title"></h2>
          <!-- <div class="spacer"></div> -->
          <span class="iconRight">
            <button class="iconButton small" type="button"
                    @click.stop="acceptAllCookies">
              <img :src="icons.close" width="100%"/>
            </button>
          </span>
        </div>
        <div class="popupContent text-center">
          <div class="cookie-popup-content" v-html="popups.cookie.content"></div>
        </div>
        <div class="popupActions flex justify-center">
          <button class="button rounded block" @click.stop="acceptAllServices">
            <span>Alle akzeptieren</span>
          </button>
        </div>
      </div>
    </div>
    <div id="typeformTrigger"
      :class="{'second': chatBotActive}"
      v-if="user.card.survey_id && $route.name === 'Home'"
      @click.stop.prevent="toggleTypeform(user.card.survey_id)">
      <p v-text="user.card.survey_name"></p>
      <img :src="icons.white_close" width="100%" />
    </div>
    <div id="typeformHolder">
      <span class="ol" @click.stop.prevent="closeTypeForm(user.card.survey_id)"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import initStore from '@/store/modules/init'
import userStore from '@/store/modules/user'
import pagesStore from '@/store/modules/page'
import MainHeader from '@/components/header'
import MainFooter from '@/components/footer'
import Modal from '@/components/modal'
import uiStore from '@/store/modules/ui'
import CookiePopup from '@/components/cookie-popup'
import { createPopover } from '@typeform/embed'
import '@typeform/embed/build/css/popover.css'
import PopupImage from '@/components/popups/popup-image'
import PopupText from '@/components/popups/popup-text'

const smallDevice = window.matchMedia('(max-width: 767px)')
const mediumDevice = window.matchMedia('(max-width: 992px)')
const tabletPortraitDevice = window.matchMedia('(min-width: 992px) and (max-width: 1024px)')
export default {
  data () {
    return {
      isHome: true,
      isStart: false,
      isMobile: false,
      isScrolling: false,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        white_close: require('@/assets/images/new_close.svg')
      },
      isMounted: false,
      surveyModal: null,
      surveyTimeout: null,
      surveyMountTimeout: null,
      popupText: false,
      popupImage: false,
      tType: '',
      iType: ''
    }
  },
  components: {
    Modal,
    MainHeader,
    MainFooter,
    CookiePopup,
    PopupImage,
    PopupText
  },
  computed: {
    ...mapState('page', ['popups']),
    ...mapState('ui', [
      'device',
      'cookieModal',
      'showModal',
      'cookiePopup',
      'reload',
      'surveyPopupText',
      'surveyPopupImage'
    ]),
    ...mapState('user', ['user']),
    chatBotActive () {
      return this.$store.state.init?.init?.settings?.globals?.chatbot
    }
  },
  mixins: [
    dynamicModule('ui', uiStore),
    dynamicModule('init', initStore),
    dynamicModule('user', userStore),
    dynamicModule('page', pagesStore)
  ],
  watch: {
    showModal (val) {
      let el = document.getElementsByTagName('body')[0]
      el.style.overflow = val ? 'hidden' : ''
    },
    cookieModal (val) {
      let el = document.getElementsByTagName('body')[0]
      el.style.overflow = val ? 'hidden' : ''
    },
    '$route.name' (nv, ov) {
      this.isHome = nv === 'Home'
      this.isStart = nv === 'Start'
      clearTimeout(this.surveyTimeout)
      clearTimeout(this.surveyMountTimeout)
      if (this.surveyModal) {
        this.surveyModal.close()
        const tfh = document.querySelector('#typeformHolder')
        const tft = document.querySelector('#typeformTrigger')
        tfh && tfh.classList.remove('active')
        tft && tft.classList.remove('active')
      }
      // debugger
      if (nv === 'Home') {
        this.surveyModal = null
        this.initTypeForm()
      }
      this.handleChatbot(this.isStart)
    },
    reload (nv, ov) {
      clearTimeout(this.surveyTimeout)
      clearTimeout(this.surveyMountTimeout)
      if (ov && this.$route.name === 'Home') {
        this.surveyModal = null
        this.initTypeForm()
      }
    }
  },
  methods: {
    ...mapActions('ui', [
      'changeDevice',
      'changeCookieModal',
      'changeNavItems',
      'changeNav',
      'changeSearchBar',
      'changeCookiePopup',
      'changePopupImage',
      'changePopupText'
    ]),
    ...mapActions('init', ['loadInit']),
    ...mapActions('user', [
      'addCard',
      'addCardType',
      'addLevel',
      'addSelectedCards',
      'addSwitcher',
      'addCookies',
      'addCookieConsent'
    ]),
    setDevice () {
      let device = ''
      if (smallDevice.matches) {
        this.currentDevice = 3
        device = 'sm'
      } else if (mediumDevice.matches) {
        this.currentDevice = 2
        device = 'md'
      } else if (tabletPortraitDevice.matches) {
        this.currentDevice = 4
        device = 'tp'
      } else {
        this.currentDevice = 1
        device = 'lg'
      }
      this.isMobile = device === 'sm'
      this.changeDevice(device)
    },
    onScrolling () {
      let scrollPos = window.pageYOffset || document.documentElement.scrollTop
      this.isScrolling = scrollPos > 299
    },
    acceptAllServices () {
      let self = this

      this.$userCentrics.acceptAllServices().then(function () {
        self.$userCentrics.getCategoriesBaseInfo()

        localStorage.setItem('uc_essential', true)
        localStorage.setItem('uc_functional', true)
        localStorage.setItem('uc_performance', true)
        // self.$emit('close-popup', true)
        self.addCookies(true)
        self.addCookieConsent(true)
        self.changeCookiePopup(false)
        self.changeCookieModal(false)
      })
    },
    async setCard (item) {
      let selectedCards = this.user.selectedCards
      // let oldCard = this.user.card
      if (this.user.card?.id && !this.user.selectedCards?.hasOwnProperty(this.user.card?.id) && this.user.cookies) {
        // Object.assign(selectedCard, )
        selectedCards = {
          ...selectedCards,
          ...{
            [`${this.user.card.id}`]: {
              card: this.user.card,
              level: this.user.currentLevel
            }
          }
        }
        this.addSelectedCards(selectedCards)
        // this.addSwitcher(true)
      }
      if (this.user.cookies) {
        const crlvl = this.user.selectedCards[item.id]?.level
        const hasCard = this.user.selectedCards?.hasOwnProperty(item.id)
        if (!hasCard) {
          selectedCards = this.user.selectedCards
          const id = item.id
          const newCard = {
            [`${id}`]: {
              card: item,
              level: crlvl || 1
            }
          }
          selectedCards = { ...selectedCards, ...newCard }
          this.addSelectedCards(selectedCards)
        }

        await this.addCard(item)
        await this.addCardType(parseInt(item.type))
        await this.addLevel(crlvl || 1)

        // if (localStorage.getItem('uc_performance')) {
        //   if (hasCard) {
        //     await this.$gtm.trackEvent({
        //       event: 'card_changed',
        //       action: 'click',
        //       card: item.title,
        //       card_id: item.id,
        //       old_card: oldCard.title,
        //       old_card_id: oldCard.id,
        //       level_id: crlvl || 1
        //     })
        //   } else {
        //     await this.$gtm.trackEvent({
        //       event: 'card_selected',
        //       action: 'click',
        //       card: item.title,
        //       card_id: item.id
        //     })
        //   }
        // }
        this.isMounted = true
      } else this.isMounted = true
    },
    findCardBySlug (slug, cards) {
      let result = null

      cards.forEach(function (card) {
        if (card.slug === slug) {
          result = card
        }
      })

      return result
    },
    acceptAllCookies () {
      this.changeCookiePopup(false)
      this.addCookies(true)
      this.addCookieConsent(true)
    },
    toggleTypeform (card) {
      clearTimeout(this.surveyTimeout)
      clearTimeout(this.surveyMountTimeout)
      if (!this.surveyModal) {
        if (!sessionStorage.getItem(card)) {
          sessionStorage.setItem(card, '1')
        }
        this.surveyModal = createPopover(card, {
          container: document.getElementById('typeformHolder'),
          position: 'right',
          height: '530',
          autoResize: true
        })
      }
      this.surveyModal.toggle()
      document.querySelector('#typeformHolder').classList.toggle('active')
      document.querySelector('#typeformTrigger').classList.toggle('active')
    },
    initTypeForm () {
      const card = this.$store.state.init.init.cards.find(item => item.id === this.user.card.id)

      let surveyStartTime = Date.parse(card.survey_start_time)
      let surveyEndTime = Date.parse(card.survey_end_time)
      // let typeformTrigger = document.querySelector('#typeformTrigger')
      let nowDate = Date.now()

      if (isNaN(surveyStartTime)) {
        surveyStartTime = nowDate
      }

      if (isNaN(surveyEndTime)) {
        surveyEndTime = nowDate
      }
      if (surveyStartTime <= nowDate && surveyEndTime >= nowDate && card.survey_time && card.survey_time > 0 && !sessionStorage.getItem(card.survey_id)) {
        this.surveyTimeout = setTimeout(() => {
          this.toggleTypeform(card.survey_id)
        }, card.survey_time * 1000)
      }
    },
    closeTypeForm () {
      clearTimeout(this.surveyTimeout)
      clearTimeout(this.surveyMountTimeout)
      this.surveyModal.close()
      document.querySelector('#typeformHolder').classList.remove('active')
      document.querySelector('#typeformTrigger').classList.remove('active')
    },
    handlePopupImage (data) {
      this.changePopupImage(data)
    },
    handlePopupText (data) {
      this.changePopupText(data)
    },
    handleToggle (type) {
      switch (type) {
        case 'survey_popup_text':
          this.changePopupText(null)
          break
        default:
          this.changePopupImage(null)
          break
      }
    },
    handleChatbot (isStart = false) {
      if (!this.chatBotActive) {
        if (this.hasChatbot()) {
          window.knowhere.api.hideWidget()
        }
        return
      }
      if (!isStart) {
        if (!this.hasChatbot()) {
          this.createChatbot()
        } else {
          window.knowhere.api.setWidgetPosition(this.device !== 'sm' ? 0 : 70, 32, 'px', false)
          window.knowhere.api.showWidget()
        }
      } else {
        if (this.hasChatbot()) {
          window.knowhere.api.hideWidget()
        }
      }
    }
  },
  mounted () {
    this.isHome = this.$route.name === 'Home'
    this.isStart = this.$route.name === 'Start'
    window.addEventListener('resize', this.setDevice)
    window.addEventListener('scroll', this.onScrolling)
    this.onScrolling()
    this.setDevice()
    // this.$route.name !== 'Start' && this.loadInit()
    this.changeNavItems()
    this.changeNav(false)
    this.changeSearchBar(false)
    this.changeCookieModal(false)

    // this.popups.length === 0 && this.$route.name !== 'Home' && this.loadHome()
    let self = this

    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that do not reference external resources
      if (target && target.matches(".cookie-popup-content a[href='" + process.env.VUE_APP_PUBLIC_PATH + "cookie-popup']") && target.href) {
        event.preventDefault()
        self.changeCookiePopup(false)
        self.addCookieConsent(true)
        self.addCookies(true)
        self.changeCookieModal(true)
      }
    })
    // const
    if (this.$route.name === 'Home') {
      this.surveyMountTimeout = setTimeout(() => {
        this.initTypeForm()
      }, 300)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setDevice)
    window.removeEventListener('scroll', this.onScrolling)
    clearTimeout(this.surveyTimeout)
    clearTimeout(this.surveyMountTimeout)
  },
  created () {
    if (this.$route.query.reset !== undefined) {
      localStorage.clear()
      sessionStorage.clear()

      this.$router.push({ name: 'Start' })
    }

    if (this.$route.query.card !== undefined && this.$route.name !== 'Start') {
      this.loadInit().then(resp => {
        let card = this.findCardBySlug(this.$route.query.card, resp.cards)
        if (card !== null && Object.keys(card).length > 0) {
          this.setCard(card)
        }

        this.handleChatbot(this.$route.name === 'Start')
      })
      // !this.hasRecaptcha() && this.createRecaptcha()
    } else if (this.$route.name !== 'Start') {
      this.loadInit().then(() => {
        this.isMounted = true
        this.handleChatbot(this.$route.name === 'Start')
      })
      // !this.hasRecaptcha() && this.createRecaptcha()
    } else {
      this.isMounted = true
    }
    this.$nextTick(() => {
      this.changePopupText(null)
      this.changePopupImage(null)
    })
  }
}
</script>
